<template>
  <div>
    <div class="xrow" style="margin-bottom: 10px">
      <div class="col-1-4">
        <label>De</label>
        <datetime
          input-class="form-control"
          v-model="dataInicio"
          type="date"
          :max-datetime="fim"
          placeholder="Data inicial"
          :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
          value-zone="local"
          format="dd/MM/yyyy"
        />
      </div>
      <div class="col-1-4">
        <label>Até</label>
        <datetime
          input-class="form-control"
          v-model="dataFim"
          type="date"
          :min-datetime="inicio"
          placeholder="Data final"
          :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
          value-zone="local"
          format="dd/MM/yyyy"
        />
      </div>
      <div class="col-1-4">
        <label>&nbsp;Tipos</label><br>
        <select class="form-control" v-model="tiposReserva">
          <option value="0">Todos os tipos</option>
          <option value="1">Com pendência de entrega</option>
          <option value="2">Sem pendência de entrega</option>
        </select>
      </div>
      <div class="col-1-4">
        <label>&nbsp;</label><br>
        <button class="button button-primary" v-on:click="loadReservas">
          Buscar
        </button>
      </div>
      <div class="col-1-12">
        <div class="pull-right">
          <label>&nbsp;</label><br>
          <button
            v-on:click="openCreate"
            class="button button-success button-block"
          >
            <fa-icon icon="plus" /> Nova Reserva
          </button>
        </div>
      </div>
    </div>
    <Edit
      :item="currentItem"
      :close="closeEdit"
      :submit="submit"
      :update="update"
      :colaboradores="colaboradores"
      :mershandisings="mershandisings"
      :clientes="clientes"
      :handleClientes="handleClientes"
    ></Edit>    
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Mershandising</th>
            <th>Cliente</th>
            <th>Colaborador</th>
            <th>Quantidade</th>
            <th>Pendente</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" v-bind:key="item.id">
            <td class="highlight">{{ item.id }}</td>
            <td v-if="item.mershandising">
              {{ item.mershandising_id }} - {{ item.mershandising.descricao }}
            </td>
            <td v-else>
              {{ item.mershandising_id }} - Sem descrição
            </td>
            <td>
              <div v-if="item.cliente">
                {{ item.cliente.id }} - {{ item.cliente.razSoc }}
              </div>
            </td>
            <td>
              <div v-if="item.colaborador.nome">
                {{ item.colaborador.id }} - {{ item.colaborador.nome }}
              </div>
            </td>
            <td>{{ item.quantidade }}</td>
            <td>{{ item.qtd_disponivel }}</td>
            <td class="actions">
              <button
                type="button"
                class="button button-primary"
                v-on:click="openEdit(item)"
              >
                <fa-icon icon="pencil-alt" />
              </button>
              &nbsp;
              <button v-if="item.permite_deletar"
                type="button"
                name="button"
                v-on:click="remove(item.id)"
                class="button button-error"
              >
                <fa-icon icon="trash-alt" />
              </button>

              <button v-else
                type="button"
                name="button"
                v-on:click="remove(item.id)"
                class="button button-error"
                disabled
              >
                <fa-icon icon="trash-alt" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import clone from "just-clone";
import _findIndex from "lodash/findIndex";
import { get, post, put, del } from "../../../../helpers/apiRequest";
import Edit from "./Edit";
import moment from 'moment';

export default {
  name: "reserva",
  components: {
    Edit,
  },
  props: ["mershandisings", "colaboradores"],
  data() {
    return {
      descPlural: "",
      loading: false,
      items: [],
      currentItem: {},
      clientes: [],
      table: {
        filter: "",
        columns: [
          {
            label: "Mershandindising ID",
            field: "mershandising_id",
            align: "center",
            sortable: false,
          },
          { label: "Quantidade", field: "quantidade", sortable: false },
        ],
        paginator: {},
      },
      dataInicio: moment()
        .subtract(3, 'months')
        .format('YYYY-MM-DD'),
      dataFim: moment().format('YYYY-MM-DD'),
      tiposReserva: 0
    };
  },
  computed: {
    inicio() {
      return moment(this.dataInicio).format('YYYY-MM-DD');
    },

    fim() {
      return moment(this.dataFim).format('YYYY-MM-DD');
    },
  },
  methods: {
    loadReservas() {
      if (this.dataInicio && this.dataFim) {
        this.loading = true;
        get(`/admin/mershandising/reservas?data_inicio=${
            this.inicio
          }&data_fim=${this.fim}&tipo=${this.tiposReserva}`)
          .then((json) => {
            this.items = json.itens;
            this.table.paginator = json.paginator;
            this.loading = false;
          })
          .catch((err) => (this.loading = false));
      }
    },
    openCreate() {
      const item = {
        mershandising_id: null,
        cliente_id: null,
        colaborador_id: null,
        quantidade: null,
      };
      this.openEdit(item);
    },
    closeCreate() {
      this.$modal.hide("mEdit");
    },
    openEdit(item) {      
      this.handleClientes(item.colaborador_id);
      this.currentItem = clone(item);
      this.$modal.show("mEdit");
    },

    handleClientes(id) {
      if (id !== null) {
        post(`/admin/mershandising/clientes/${id}`)
          .then((json) => {
            this.clientes = json;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    closeEdit() {
      this.$modal.hide("mEdit");
    },
    submit(item) {
      post("/admin/mershandising/reservas/save", item)
        .then((json) => {
          this.$modal.hide("mEdit");
          this.loadReservas();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });           
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            title: "Ops!",
            text: error.message,
            icon: "error",
          });
        });
    },
    update(item) {
      put(`/admin/mershandising/reservas/update/${item.id}`, item)
        .then((json) => {
          this.$modal.hide("mEdit");
          this.loadReservas();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });          
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            title: "Ops!",
            text: error.message,
            icon: "error",
          });
        });
    },
    remove(id) {
      this.$swal({
        title: "Confirma Remover?",
        text: "Essa ação não poderá ser desfeita",
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/mershandising/reservas/delete/${id}`)
              .then((json) => {
                this.loading = false;
                this.loadReservas();
                this.$swal({
                  title: 'Removido com sucesso',
                  text: json.message,
                  showCancelButton: false,
                  cancelButtonText: 'Fechar',
                  showConfirmButton: true,
                  icon: 'success',
                });  
              })
              .catch((error) => {
                this.loading = false;
                this.$swal({
                  title: "Ops!",
                  text: error.message,
                  icon: "error",
                });
              });
          }
        })
        .catch(() => {});
    },
  },
  beforeMount() {
    this.loading = true;
    this.loadReservas();
  },
};
</script>
