<template>
  <div>
    <Edit
      :item="currentItem"
      :close="closeEdit"
      :submit="submit"
      :update="update"
      :btnUpdate="btnUpdate"
    ></Edit>
    <div
      class="filters row"
      style="margin-top:0;     justify-content: flex-end;"
    >
      <div class="col-1-12">
        <div class="pull-right">
          <button
            v-on:click="openCreate"
            class="button button-success button-block"
          >
            <fa-icon icon="plus" /> Nova Ação
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Descrição</th>
            <th>Quantidade minima de Fotos</th>
            <th>Etapa</th>
            <th>Valida cliente</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" v-bind:key="item.id">
            <td class="highlight">{{ item.id }}</td>
            <td>{{ item.descricao }}</td>
            <td>{{ item.qtd_min_fotos }}</td>
            <td>{{ item.requer_etapa }}</td>
            <td>
              <span class="label success" v-if="item.valida_cliente">Sim</span>
              <span class="label error" v-else>Não</span>
            </td>
            <td>
              <span class="label success" v-if="item.ativo">Ativo</span>
              <span class="label error" v-else>Inativo</span>
            </td>
            <td class="actions">
              <button
                type="button"
                class="button button-primary"
                v-on:click="openEdit(item)"
              >
                <fa-icon icon="pencil-alt" />
              </button>
              &nbsp;
              <button
                type="button"
                name="button"
                v-on:click="remove(item.id)"
                class="button button-error"
              >
                <fa-icon icon="trash-alt" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import clone from "just-clone";
import { get, post, put, del } from "../../../../helpers/apiRequest";
import Edit from "./Edit";

export default {
  name: "acao",
  components: {
    Edit,
  },
  props: ["mrshandisings"],

  data() {
    return {
      descPlural: "",
      loading: false,
      items: [],
      currentItem: {},
      btnUpdate: { ativo: false },
      table: {
        filter: "",
        columns: [
          { label: "ID", field: "id", align: "center", sortable: false },
          { label: "Descricao", field: "descricao", sortable: false },
          { label: "Status", field: "ativo", sortable: false },
          { label: "Valida cliente", field: "valida_cliente", sortable: false },
          {
            label: "Quantidade de fotos",
            field: "qtd_min_fotos",
            sortable: false,
          },
          { label: "Etapa", field: "requer_etapa", sortable: false },
        ],
        paginator: {},
      },
    };
  },
  computed: {},
  methods: {
    loadAcoes() {
      get(`/admin/mershandising/acoes`)
        .then((json) => {
          this.items = json.itens;
          this.table.paginator = json.paginator;
          this.loading = false;
        })
        .catch((err) => (this.loading = false));
    },
    openCreate() {
      const item = {
        id: null,
        descricao: null,
        valida_cliente: null,
        requer_etapa: null,
        qtd_min_fotos: null,
        ativo: 1,
      };
      this.btnUpdate.ativo = false;
      this.currentItem = clone(item);
      this.$modal.show("mEdit");
    },
    closeCreate() {
      this.$modal.hide("mEdit");
    },
    openEdit(item) {
      this.btnUpdate.ativo = true;
      this.currentItem = clone(item);
      this.$modal.show("mEdit");
    },
    closeEdit() {
      this.$modal.hide("mEdit");
    },
    submit(item) {
      post("/admin/mershandising/acoes/save", item)
        .then((json) => {
          this.$modal.hide("mEdit");
          this.loadAcoes();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });           
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            title: "Ops!",
            text: error.message,
            icon: "error",
          });
        });
    },
    update(item) {
      put(`/admin/mershandising/acoes/update/${item.id}`, item)
        .then((json) => {
          this.$modal.hide("mEdit");
          this.loadAcoes();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });          
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            title: "Ops!",
            text: error.message,
            icon: "error",
          });
        });
    },
    remove(id) {
      this.$swal({
        title: "Confirma Remover?",
        text: "Essa ação não poderá ser desfeita",
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/mershandising/acoes/delete/${id}`)
              .then((json) => {
                this.loading = false;
                this.loadAcoes();
                this.$swal({
                  title: 'Removido com sucesso',
                  text: json.message,
                  showCancelButton: false,
                  cancelButtonText: 'Fechar',
                  showConfirmButton: true,
                  icon: 'success',
                });  
              })
              .catch((error) => {
                this.loading = false;
                this.$swal({
                  title: "Ops!",
                  text: error.message,
                  icon: "error",
                });
              });
          }
        })
        .catch(() => {});
    },
  },
  beforeMount() {
    this.loading = true;
    this.loadAcoes();
  },
};
</script>
