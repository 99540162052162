<template>
  <div>    
    <div class="xrow" style="margin-bottom: 10px">
      <div class="col-1-4">
        <label>De</label>
        <datetime
          input-class="form-control"
          v-model="dataInicio"
          type="date"
          :max-datetime="fim"
          placeholder="Data inicial"
          :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
          value-zone="local"
          format="dd/MM/yyyy"
        />
      </div>
      <div class="col-1-4">
        <label>Até</label>
        <datetime
          input-class="form-control"
          v-model="dataFim"
          type="date"
          :min-datetime="inicio"
          placeholder="Data final"
          :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
          value-zone="local"
          format="dd/MM/yyyy"
        />
      </div>
      <div class="col-1-4">
        <label>&nbsp;</label><br>
        <button class="button button-primary" v-on:click="loadMershandising">
          Buscar
        </button>
      </div>
    </div>
    <div class="filters row" style="margin-top:0; justify-content: flex-end;">
      <Info
        :fotos="fotos"
        :close="closeInfo"
      ></Info>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Cliente</th>
            <th>Observação</th>
            <th>Reserva ID</th>
            <th>Mershandising</th>
            <th style="text-align: center">Imagem</th>
            <th>Quantidade</th>
            <th>Criação</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" v-bind:key="item.id" >
            <td class="highlight">{{ item.id }}</td>
            <td v-if="item.cliente">
              {{ item.cliente.id }} - {{ item.cliente.razSoc }}
            </td>
            <td v-else>
              -
            </td>
            <td>{{ item.observacao }}</td>
            
            <td v-if="item.mershandising_reserva">
              {{ item.mershandising_reserva.id }}
            </td>
            <td v-else>
              -
            </td>
            
            <td v-if="item.mershandising_reserva">
              {{ item.mershandising_reserva.mershandising.id }} - {{ item.mershandising_reserva.mershandising.descricao }}
            </td>
            <td v-else>
              -
            </td>

            <td >
              <div style="display: flex; justify-content: center;">
                <img :src="item.fotos[0].imagem" class="thumbnail thumbnail-extra-small" v-if="conferiFotos(item.fotos)" v-on:click="openInfo(item)">
              </div>
            </td>
            <td>{{ item.quantidade }}</td>
            <td>{{ item.datahora_criacao  | date }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script>
import { isEmpty } from "lodash";
import { get } from "../../../../helpers/apiRequest";
import Info from "./Info.vue";
import moment from 'moment';

export default {
  name: "mershandising-list",
  props: ["entrega"],
  components: { isEmpty },
  data() {
    return {
      loading: false,
      items: [],
      fotos: {},
      table: {
        filter: "",
        columns: [
          { label: "ID", field: "id", align: "center", sortable: false },
          {
            label: "Mershandising ID",
            field: "mershandising_id",
            align: "center",
            sortable: false,
          },
          { label: "Quantidade", field: "quantidade", sortable: false },
        ],
      },
      dataInicio: moment()
        .subtract(3, 'months')
        .format('YYYY-MM-DD'),
      dataFim: moment().format('YYYY-MM-DD'),
    };
  },
  components: {
    Info,
  },
  computed: {
    inicio() {
      return moment(this.dataInicio).format('YYYY-MM-DD');
    },

    fim() {
      return moment(this.dataFim).format('YYYY-MM-DD');
    },
  },
  methods: {
    loadMershandising() {
      if (this.dataInicio && this.dataFim) {
        get(`/admin/mershandising/entregas?data_inicio=${
            this.inicio
          }&data_fim=${this.fim}`)
          .then((json) => {
            this.items = json.itens;
            this.table.paginator = json.paginator;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
    conferiFotos(fotos) {
      return !isEmpty(fotos);
    },
    openInfo(item) {
      const arrayFotos = [];

      item.fotos.forEach((item) => {
        arrayFotos.push({
          id: item.id,
          big: item.imagem,
          thumb: item.imagem,
        });
      });

      this.fotos = arrayFotos;
      this.$modal.show("mInfo");
    },
    closeInfo() {
      this.$modal.hide("mInfo");
    },
  },
  beforeMount() {
    this.loading = true;
    this.loadMershandising();
  },
};
</script>
